<template>
  <b-card>
    <div>
      <b-row>
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start pb-1"
        >
          <b-button
            v-if="$can('add', 'Language')"
            variant="primary"
            :to="{ name: 'administration-addLanguage'}"
          >
            {{ $t('General.Add') + $t('Language') }}
          </b-button>
        </b-col>
        <!-- input search -->
        <b-col
          cols="6"
          md="6"
        >
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">{{ $t('General.Search') }}</label>
                <b-form-input
                  v-model="searchTerm"
                  :placeholder="$t('General.Search')"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <!-- alert -->
          <b-alert
            v-height-fade.appear
            variant="danger"
            :show="showError"
            class="text-left p-1"
          >
            <ul class="list-style-icons mb-0">
              <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
            </ul>
          </b-alert>
        </b-col>
        <b-col cols="3" />
        <b-col cols="3" />
      </b-row>

      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          responsive
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Action -->
            <span v-if="props.column.field === 'action' && ($can('delete', 'Language') || $can('update', 'Language'))">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="$can('update', 'Language')"
                    :to="{ name: 'administration-updateLanguage', params: { id: props.row.id } }"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('General.Edit') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$can('delete', 'Language')"
                    @click="confirmText(props.row.id, $store.state.appConfig.layout.isRTL ? props.row.name_ar : props.row.name_en)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('General.Delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t('Showing 1 to') }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10','50','100']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> {{ $t('of') }} {{ props.total }} {{ $t('General.entries') }} </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-overlay>
    </div>
  </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton, BRow, BCol, BAlert, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import language from '@/service/administration/language'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    BAlert,
    BOverlay,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: this.$t('General.ArabicName'),
          field: 'name_ar',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('General.ArabicName')}`,
          },
        },
        {
          label: this.$t('General.EnglishName'),
          field: 'name_en',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('General.EnglishName')}`,
          },
        },
      ],
      rows: [],
      searchTerm: '',
      showError: false,
      showOverLay: false,
      apiErrors: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.layout.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    if (this.$can('update', 'Language') || this.$can('delete', 'Language')) {
      this.columns.push({
        label: this.$t('General.Action'),
        field: 'action',
      })
    }
    this.getApi()
  },
  methods: {
    async confirmText(id, name) {
      this.$swal({
        title: this.$t('General.Are you sure Delete?'),
        text: `${this.$t('General.You will not be able to undo the deletion of this')} (${name})`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('General.Yes, delete it!'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteApi(id).then(res => {
            if (res.data === 1) {
              this.$swal({
                icon: 'success',
                title: this.$t('General.Deleted!'),
                text: `${name} ${this.$t('General.has been deleted')}`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getApi()
            } else {
              this.$swal({
                icon: 'error',
                title: this.$t('General.Deleted!'),
                text: `${name} ${this.$t('General.has been Not deleted')}`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      })
    },
    async deleteApi(id) {
      this.showOverLay = true
      const resultData = await language.deleteAxios(id)
        .then(response => response)
        .catch(error => {
          this.apiErrors = error
          this.showError = true
          return error
        }).finally(() => { this.showOverLay = false })
      return resultData
    },
    async getApi() {
      this.showOverLay = true
      await language.getAxios().then(response => {
        this.rows = response.data
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
